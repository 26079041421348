import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { navigationCSS } from '../../theme/page';

const navAnchorStyles = (props) => css`
  color: ${props.theme.color.white};
  display: block;
  font-size: ${props.theme.modularScale.small};
  text-decoration: none;
`;

const NavLink = styled.a`
  ${navAnchorStyles}
  cursor: pointer;
`;

const NavNoLink = styled.p`
  ${navAnchorStyles}
`;

const NavLabel = styled.label`
  ${navAnchorStyles}
  transition: background-image ${(props) => props.theme.animation.fast} ${(props) => props.theme.animation.easeInQuad};
`;

const SecondaryTrigger = styled.input`
  opacity: 0;
  width: 0;
  position: absolute;

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    display: none;
  }

  &:checked {
    ~ ${NavLabel} {
      ${(props) => props.theme.arrow('left', props.theme.color.brandLight, 'right center', 5)}
    }

    ~ ul {
      transform: translate3d(0, 0, 0);
      visibility: visible;
      overflow: auto;
    }
  }
`;

const SecondaryItem = styled.li`
  line-height: 3rem;

  > ${NavLabel} + ${NavLink},
  > ${NavLabel} + ${NavNoLink} {
    display: none;
  }

  > ${NavLink}, > ${NavLabel}, > ${NavNoLink} {
    font-family: ${(props) => props.theme.font.sans};
    line-height: 4rem;
  }

  > ${NavLabel} {
    ${(props) => props.theme.arrow('right', props.theme.color.white, 'right center', 5)}
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    padding: 4rem 0;

    ${(props) => (props.noChildrenViewAll ? css`
      margin-top: 1rem;
      border-top: 1px solid ${props.theme.color.brand};

      > ${NavLink} ,
      > ${NavNoLink} {
        line-height: 4rem !important;
      }
    ` : '')}

    ${(props) => (props.viewAll ? css`display: none;` : '')}

    > ${NavLabel} {
      display: none;
    }

    > ${NavLabel} + ${NavLink},
    > ${NavLabel} + ${NavNoLink} {
      display: block;
    }

    > ${NavLink},
    > ${NavNoLink} {
      border-bottom: 1px solid ${(props) => props.theme.color.brand};
      color: ${(props) => props.theme.color.brand};
      font-family: ${(props) => props.theme.font.caption};
      letter-spacing: 0.24em;
      line-height: 4rem;
      margin-bottom: 1rem;
      text-align: left;
      text-transform: uppercase;
    }

    > ${NavLink} {
      transition: color ${(props) => props.theme.animation.default};

      &:hover {
        color: ${(props) => props.theme.color.brandLight};
      }
    }
  }
`;

const NoChildrenViewAll = styled.li`
  padding-bottom: 4rem;
  text-align: left;

  > ${NavLink} {
    border-top: 1px solid ${(props) => props.theme.color.brand};
    color: ${(props) => props.theme.color.brand};
    font-size: 13px;
    line-height: 4rem;
    margin-top: 1rem;
    transition: color ${(props) => props.theme.animation.default};

    &:hover {
      color: ${(props) => props.theme.color.brandLight};
    }
  }
`;

const NoChildrenLabel = styled.li`
  padding-top: 4rem;
  text-align: left;

  ${NavLink} {
    border-bottom: 1px solid ${(props) => props.theme.color.brand};
    color: ${(props) => props.theme.color.brand};
    font-family: ${(props) => props.theme.font.caption};
    font-size: ${(props) => props.theme.modularScale.small};
    letter-spacing: 0.24em;
    line-height: 4rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    transition: color ${(props) => props.theme.animation.default};

    &:hover {
      color: ${(props) => props.theme.color.brandLight};
    }
  }

  ~ ${SecondaryItem}:last-of-type {
    padding-bottom: 4rem;
  }
`;

const noChildrenStyles = (props) => css`
  display: flex;
  flex-direction: column;

  > * {
    /* NOTE: width to make up for grid gap */
    width: calc(20% - 2.4rem);
  }

  ${SecondaryItem} {
    padding: 0;

    ${NavLink},
    ${NavNoLink} {
      font-family: ${props.theme.font.sans};
      font-size: 1.3rem;
      letter-spacing: initial;
      line-height: 2.5rem;
      margin: 0;
      padding: 0;
      text-transform: initial;
      border-bottom: 0 none;
    }
  }
`;

const SecondaryList = styled.ul`
  z-index: ${(props) => props.theme.layers.centerstage};

  > li > ul {
    transform: translate3d(28rem, 0, 0);
    visibility: hidden;
  }

  ${NoChildrenLabel}, ${NoChildrenViewAll} {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    li > ul {
      transform: translate3d(38rem, 0, 0);
      visibility: hidden;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    background: ${(props) => props.theme.color.background};
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;

    ${NoChildrenLabel}, ${NoChildrenViewAll} {
      display: block;
    }

    ${navigationCSS}
    ${(props) => (props.noChildren ? noChildrenStyles : css`
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: repeat(5, 1fr);
    `)}

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &::before {
      background-image: url('${process.env.NEXT_PUBLIC_ASSET_HOST}/images/maisonette-navigation-left-bg.jpg');
      background-repeat: no-repeat;
      background-position: 0 0;
    }

    &::after {
      background-image: url('${process.env.NEXT_PUBLIC_ASSET_HOST}/images/maisonette-navigation-right-bg.jpg');
      background-repeat: no-repeat;
      background-position: 100% 0;
    }

    ${SecondaryItem} {
      > ul {
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
    }
  }
`;

export {
  NavLabel,
  NavLink,
  NavNoLink,
  NoChildrenLabel,
  NoChildrenViewAll,
  SecondaryItem,
  SecondaryList,
  SecondaryTrigger
};
